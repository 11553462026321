import React from 'react';
import SEO from "../../common/SEO";
import HeaderTopNews from "../../common/header/HeaderTopNews";
import HeaderOne from "../../common/header/HeaderOne";
import {Link} from "react-router-dom";
import {FiArrowRight, FiCheck, FiStar} from "react-icons/fi";
import Separator from "../../elements/separator/Separator";
import CalltoActionTwo from "../../elements/calltoaction/CalltoActionTwo";
import {careerGoal} from "../../data/career/careerGoal";
import FooterTwo from "../../common/footer/FooterTwo";
import Copyright from "../../common/footer/Copyright";
import {careerContact} from "../../data/career/careerContact";
import bg_career from "../../assets/images/bg/bg-career.jpg";

export const Career = () => {
    return (
        <>
            <SEO title="Career || Tempest" />
            <main className="page-wrapper">
                <HeaderTopNews />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                <div className="slider-area slider-style-1 bg-transparent variation-2 height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">Your future is our future{" "}</h1>
                                    <p className="description">We want to connect people with businesses and help grow your communities.</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/jobs">Find Jobs <i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium round btn-icon" to="/contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img style={{borderRadius: 10}} src={bg_career} alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <CalltoActionTwo data={careerGoal}  button={false}/>

                <Separator />

                <div className="slider-area slider-style-1 bg-transparent variation-2 height-650">
                    <div className="container">
                        <div className="row mt--30">
                    <div className="col-lg-12 ">

                            <div className="inner">
                                <div className="row row--0">
                                    <div className="col-lg-4">
                                        <div className="px-2">
                                            <h1  style={{fontWeight:"2rem !"}}>Explore your</h1>
                                            <h1 className="theme-gradient">future</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                            <p className="description px-0" >Our team will help and guide you with career exploration. We understand that everyone has their own goals and aspirations and we want to help you reach that point. Explore what possibilities are waiting out there for you! .</p>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
                    </div>
                </div>

                <Separator />

                <div className="slider-area slider-style-1 bg-transparent variation-2 height-650">
                    <div className="container">
                        <div className="row mt--30">
                            <div className="col-lg-12 ">

                                <div className="inner">
                                    <div className="row row--0">
                                        <div className="col-lg-6">
                                            <div className="px-2">
                                                <h1 className="theme-gradient"  style={{fontWeight:"2rem !"}}>Career Assistance</h1>
                                            </div>
                                            <div className="px-2">
                                                <p className={"description px-0"}>Tackle your toughest career obstacles with help from our team of elite career coaches.</p>
                                            </div>
                                            <div className="px-2">
                                                <p className={"description px-0"}>From resume rewrites to interview prep, leadership training, and more — we’ve got you covered!</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mt_md--40 mt_sm--40">

                                                <div className="inner">
                                                    <ul >
                                                        <li>
                                                            <h4 className="theme-gradient">Feedback</h4>
                                                            <h5 className={"subtitle"}>Get personalized feedback from Tempest advisors on how to polish your resume and create a story that gets noticed.</h5>

                                                        </li>
                                                        <li>
                                                            <h4 className="theme-gradient">Insights</h4>
                                                            <h5 className={"subtitle"}>Receive first hand insights from a team of industry specialists that can help you further your career.</h5>
                                                        </li>
                                                    </ul>
                                                </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                {/* End Brand Area  */}
                <FooterTwo data={careerContact}/>
                <Copyright />

            </main>
        </>
    )
}