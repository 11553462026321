export const tagMap = {
    "Corporate":{
        name: "Corporate",
        id: 1,
    },
    "Agency":{
        name: "Agency",
        id: 2
    },
    "Creative": {
        name: "Creative",
        id: 3
    },
    "Design": {
        name: "Design",
        id: 4
    },
    "Development": {
        name: "Development",
        id: 5
    },
    "Mobile": {
        name: "Mobile",
        id: 6
    },
    "Web3": {
        name: "Web3",
        id: 7
    },
    "Blockchain": {
        name: "Blockchain",
        id: 8
    },
    "Management": {
        name: "Management",
        id: 9
    },
    "Business": {
        name: "Business",
        id: 10
    }
}