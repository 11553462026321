export const disciplineMap = {
    "Engineering & Technical": {
        name: "Engineering & Technical",
        id: 1
    },
    "Marketing": {
        name: "Marketing",
        id: 2
    },
    "Operations": {
        name: "Operations",
        id: 3
    },
    "Data Science": {
        name: "Data Science",
        id: 4
    },
    "Software Development": {
        name: "Software Development",
        id: 5
    },
    "Information Security": {
        name: "Information Security",
        id: 6
    },
    "Medical & Health": {
        name: "Medical & Health",
        id: 7
    },
    "Finance": {
        name: "Finance",
        id: 8
    },
    "Product Manager": {
        name: "Product Manager",
        id: 9
    },
    "Human Resources": {
        name: "Human Resources",
        id: 10
    },
    "It Operations": {
        name: "It Operations",
        id: 11
    },
    "Clinical Operations": {
        name: "Clinical Operations",
        id: 12
    },
    "Doctors / Physicians": {
        name: "Doctors / Physicians",
        id: 13
    },
    "Mobile Development": {
        name: "Mobile Development",
        id: 14
    },
    "Bioengineering": {
        name: "Bioengineering",
        id: 15
    },
    "Clinical Research": {
        name: "Clinical Research",
        id: 16
    },
    "Biology Research": {
        name: "Biology Research",
        id: 18
    },
    "UI / UX": {
        name: "UI / UX",
        id: 19
    },
    "Sales": {
        name: "Sales",
        id: 20
    },
    "Test / Quality Assurance": {
        name: "Test / Quality Assurance",
        id: 21
    },
    "Project Manager": {
        name: "Project Manager",
        id: 22
    },
    "Crypto / Web3 / Blockchain": {
        name: "Crypto / Web3 / Blockchain",
        id: 23
    },
    "Emerging Technology / Innovation": {
        name: "Emerging Technology / Innovation",
        id: 24
    },
}

