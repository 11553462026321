import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import {RPOServices} from "../../data/service/RPOServices";

const ServiceOne = ({textAlign, serviceStyle, description, services = RPOServices}) => {

    return (
        <div className="row row--15 service-wrapper">
              {services.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                {description && <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>}
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;