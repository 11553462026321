import React from 'react';
import { FiArrowRight } from "react-icons/fi";
import {Link} from "react-router-dom";
const callToActionData = {
    title: "Ready to start innovating your business?",
    subtitle: "Find your solution today",
    btnText: "Contact Us",
}

const CalltoActionEight = ({data=callToActionData}) => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{data.title}</h2>
                                <h6 className="subtitle">{data.subtitle}</h6>

                                <div className="button-group text-center mt--30">
                                    <Link className="btn-default btn-medium btn-border round btn-icon" to="/jobs">Find Jobs <i className="icon"><FiArrowRight /></i></Link>
                                    <Link className="btn-default btn-medium round btn-icon" to="/contact">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionEight;