import {useEffect, useState} from "react";
import jobsData from '../../data/jobs/jobsList.json';


export const useJobFilter = () =>{
    const [jobs, setJobs] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [tags, setTags] = useState([]);

    const addDisciplines = (disciplineId) =>{
        setDisciplines((prev)=> [...prev, disciplineId])
    }

    const removeDisciplines = (disciplineId) => {
        setDisciplines((prev)=> [...disciplines.filter(each => each !== disciplineId )])
    }

    const addTag = (tagId) => {
        setTags((prev)=> [...prev, tagId])
    }

    const removeTag = (tagId) => {
        setTags((prev)=> [...tags.filter(each => each !== tagId)])
    }

    const searchJobs = async () =>{
        if (tags.length !== 0 || disciplines.length !== 0){
            let results = [];
            tags.map(tag => {
                jobsData.map((each, i) =>{
                    each.tags.includes(tag) && results.push(each)
                })
            });
            disciplines.map(discipline => {
                jobsData.map(each =>{
                    each.disciplines.includes(discipline) && !results.includes(each) && results.push(each)
                })
            });

            await setJobs([...results])

            console.log("[disciplines]", disciplines)
            console.log("[tags]", tags)
            console.log("[result]",results)
        }else {
            await setJobs([...jobsData])
        }
        return null;
    }

    useEffect(async ()=> {
        await searchJobs();
    }, [disciplines, tags])

    return {
        disciplines,
        tags,
        jobs,
        addTag: (tagId) =>  addTag(tagId),
        addDisciplines: (disciplineId) => addDisciplines(disciplineId),
        removeTag: (tagId) => removeTag(tagId),
        removeDisciplines: (disciplineId) => removeDisciplines(disciplineId)
    }

}