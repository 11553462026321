import React from 'react';
import BlogClassicData from '../../data/blog/BlogList.json';
import BlogDetailsContent from '../../components/blog/BlogDetailsContent';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import JobDetailsContent from "../../components/jobs/jobDetailContent";
import jobsList from "../../data/jobs/jobsList.json";

const JobDetails = ({match: {params: {id}}}) => {
    const jobId = parseInt(id, 10)
    const data = jobsList.filter(blog => blog.id === jobId);
    return (
        <>
            <SEO title="Job Details || Tempest" />
            <Layout>
                <div className="rn-blog-details-area">
                    <JobDetailsContent data={data[0]}  />
                </div>
            </Layout>
        </>
    )
}
export default JobDetails;