import React from 'react';
import SEO from "../../common/SEO";
import HeaderTopNews from "../../common/header/HeaderTopNews";
import HeaderOne from "../../common/header/HeaderOne";
import {Link} from "react-router-dom";
import {FiArrowRight, FiCheck, FiPlay, FiStar} from "react-icons/fi";
import Separator from "../../elements/separator/Separator";
import CalltoActionTwo from "../../elements/calltoaction/CalltoActionTwo";
import {careerGoal} from "../../data/career/careerGoal";
import FooterTwo from "../../common/footer/FooterTwo";
import Copyright from "../../common/footer/Copyright";
import {careerContact} from "../../data/career/careerContact";
import Typed from "react-typed";
import ScrollAnimation from "react-animate-on-scroll";
import {homeContact} from "../../data/home/homeContact";
import bg_home_1 from "../../assets/images/bg/bg-home-1.jpg";
import bg_home_2 from "../../assets/images/bg/bg-home-2.jpg";
import bg_home_3 from "../../assets/images/bg/bg-home-3.jpg";

export const Home = () => {
    return (
        <>
            <SEO title="Home || Tempest" />
            <main className="page-wrapper">
                <HeaderTopNews />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                <div className="slider-area slider-style-1 bg-transparent variation-2 height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">Opportunities for <br /> {"  "}
                                        <Typed
                                            strings={[
                                                "Business.",
                                                "Careers.",
                                                "Technology.",
                                                "Everyone."
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>

                                    <p className="description">We want to help build your future by providing  <br />  the solutions for success.</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/jobs">Find Jobs <i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium round btn-icon" to="/contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src={bg_home_1} style={{borderRadius: 10}} alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <div className="slider-area slider-style-1 bg-transparent variation-2 height-650">
                    <div className="container">
                        <div className="row mt--30">
                            <div className="col-lg-12 ">
                                <div className="inner">
                                    <div className="row row--0">
                                        <div className="col-lg-5">
                                            <div className="px-2">
                                                <h1  style={{fontWeight:"normal"}}>We want to help businesses and communities <br/> adapt to a <br/> <span className="theme-gradient">changing world</span>  </h1>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 align-self-center">
                                                <p className={"description px-0"}>Everyday the world is innovating faster than ever, but this leads to new sets of problems we haven’t faced before. Our goal is to resolve each and one of those problems. We strive for innovation to provide new solutions  for our clients while maintaining flexibility in our delivery. </p>

                                                {/*<h4 style={{fontWeight: 'normal', fontSize:30}} >Everyday the world is innovating faster than ever, but this leads to new sets of problems we haven’t faced before. Our goal is to resolve each and one of those problems. We strive for innovation to provide new solutions  for our clients while maintaining flexibility in our delivery. </h4>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <div className="slider-area slider-style-1 bg-transparent variation-2 height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className={"display-two"} style={{fontWeight: "normal"}}>Problems meet
                                        <span style={{fontWeight: "bold", border: "0 "}}> Solution</span>{"  "}
                                    </h1>

                                    <p className="description">At Tempest, we tailor to the needs of each individual client and aim to provide the highest quality solutions possible.</p>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}
                                        className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/career"><span>Learn More</span><i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src={bg_home_2} style={{borderRadius: 10}} alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <div className="slider-area slider-style-1 bg-transparent variation-2 height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className={"display-two"} style={{fontWeight: "normal"}}>Seek Career
                                        <span style={{fontWeight: "bold", border: "0 "}}> Opportunities</span>{"  "}
                                    </h1>

                                    <p className="description">Find what you're worth. Whether you're looking to start a new career, transitioning into a new industry, or need assistance with finding a job, we at Tempest can make that happen.</p>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}
                                        className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/career"><span>Learn More</span><i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/jobs">Find Jobs <i className="icon"><FiArrowRight /></i></Link>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img style={{borderRadius: 10}} src={bg_home_3} alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* End Brand Area  */}
                <FooterTwo data={homeContact}/>
                <Copyright />

            </main>
        </>
    )
}