import React from 'react';

import {Link} from "react-router-dom";
import {Accordion, Button, Card} from "react-bootstrap";
import {disciplineMap} from "../../../data/jobs/disciplineMap";


const Disciplines = ({addDisciplines, disciplines, removeDisciplines}) => {

    const disciplineFilterHandler = (disciplineId) => {
        if(disciplines.includes(disciplineId)){
            removeDisciplines(disciplineId)
        }else {
            addDisciplines(disciplineId)
        }
    }

    return (
        <Accordion className={`rn-accordion-style rn-accordion-04 mt--40`} defaultActiveKey="0">
            <Card>
                <Card.Header >
                    <Accordion.Toggle   as={Button} variant="link" eventKey="0">
                        Discipline
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <div className="filter-single-widget widget_filter">
                            <ul >
                                {Object.keys(disciplineMap).map((each, i) =>
                                    <li  key={i}>
                                        <Link to={'#'} onClick={()=>disciplineFilterHandler(disciplineMap[each].id)}>
                                            <span style={{fontWeight: disciplines.includes(disciplineMap[each].id) ? "bold" : "normal"}}>{disciplineMap[each].name}</span>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default Disciplines
