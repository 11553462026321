import React from 'react';
import { FiArrowRight } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";
import bg_banner from "../../assets/images/bg/bg-career-banner.jpg";

const callToActionData = {
    title: "Recruitment Process Outsourcing",
    subtitle: "Finest choice for your home & office",
    btnText: "Purchase Doob",
    description: "We help our clients succeed by creating brand identities, digital experiences, and print materials that communicate clearly.",
    banner: bg_banner
}


const getTitle = (titleSize, title) => {
    switch (titleSize){
        case "L":
            return <h2 className="title mb--0" style={{fontSize: "1.5em"}}>{title}</h2>
        case "M":
            return <h3 className="title mb--0" style={{fontSize: "1.17em"}}>{title}</h3>
        case "S":
            return <h4 className="title mb--0" style={{fontSize: "1em"}}>{title}</h4>
        case "XS":
            return <h5 className="title mb--0" style={{fontSize: "0.83em"}}>{title}</h5>
        default:
            return <h2 className="title mb--0">{title}</h2>
    }
}

const CalltoActionTwo = ({data=callToActionData, titleSize, button}) => {


    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"  style={{backgroundImage: `url(${data.banner})`}} data-black-overlay="4">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                {getTitle(titleSize, data.title)}
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <h6 className="subtitle">{data.description}</h6>
                                </ScrollAnimation>
                                <div className="call-to-btn text-center">
                                    {button && <a className="btn-default btn-icon" href="#">{data.btnText} <i className="icon"><FiArrowRight /></i></a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwo;