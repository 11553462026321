
import React from 'react';
import { FaSistrix } from "react-icons/fa";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import {Discipline, JobsList, JobTypeFilter, TagFilter} from "../../components/jobs";
import {useJobFilter} from "../../hooks/jobs/useJobFilter";

const Jobs = () => {
    const {jobs, addDisciplines, removeDisciplines, disciplines, tags, removeTag, addTag} = useJobFilter();
    return (
        <>
            <SEO title="Jobs Explorer || Tempest" />
            <Layout>
                <BreadcrumbOne
                    title="Find your true potential today <br /> Seek out jobs."
                    rootUrl="/home"
                    parentUrl="Home"
                    currentUrl="Jobs"
                />
                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row row--30">
                                <div className="col-lg-8">
                                    <div className="row mt_dec--30">
                                        <JobsList data={jobs} column="col-lg-12 mt--30" StyleVarProp="box-card-style-default card-list-view" />
                                    </div>
                                </div>
                                <div className="col-lg-4 mt_md--40 mt_sm--40">
                                    <aside className="rwt-sidebar">

                                        <div className="rbt-single-widget widget_search mt--40">
                                            <div className="inner">
                                                <form className="blog-search" action="#">
                                                    <input type="text" placeholder="Search ..." />
                                                    <button className="search-button"><FaSistrix /></button>
                                                </form>
                                            </div>
                                        </div>


                                        <JobTypeFilter />
                                        <Discipline addDisciplines={addDisciplines} removeDisciplines={removeDisciplines} disciplines={disciplines}/>

                                        <div className="rbt-single-widget widget_tag_cloud mt--40">
                                            <h3 className="title">Tags</h3>
                                           <div className="inner mt--20" >
                                                <TagFilter removeTag={removeTag} addTag={addTag} tags={tags}/>
                                            </div>
                                        </div>

                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>

            </Layout>
        </>
    )
}

export default Jobs
