import {FiAirplay, FiSmartphone, FiBarChart2, FiGitPullRequest} from "react-icons/fi";
import {MdOutlineHealthAndSafety } from "react-icons/md";
import { GiAtom } from "react-icons/gi";

export const RPOServices = [
    {
        icon: <FiAirplay />,
        title: 'Web Development',
        description: 'The entire recruitment cycle, everything from sourcing to onboarding candidates, is managed by us.'
    },
    {
        icon: <FiSmartphone />,
        title: 'Mobile Development',
        description: 'Outsourcing specific parts of the recruitment process. Flexibility to keep certain parts of the process in-house, outsourced, or both.'
    },
    {
        icon: <FiBarChart2 />,
        title: 'Data Analytics',
        description: 'We will manage the recruitment and onboarding for certain projects. Jump start your projects with a flexible team.'
    },
    {
        icon: <MdOutlineHealthAndSafety />,
        title: 'Cybersecurity',
        description: 'We will manage the recruitment and onboarding for certain projects. Jump start your projects with a flexible team.'
    },
    {
        icon: <FiGitPullRequest />,
        title: 'Blockchain',
        description: 'We will manage the recruitment and onboarding for certain projects. Jump start your projects with a flexible team.'
    },
    {
        icon: <GiAtom />,
        title: 'ML / AI',
        description: 'We will manage the recruitment and onboarding for certain projects. Jump start your projects with a flexible team.'
    },
]