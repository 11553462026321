import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Everyday the world is innovating faster than ever, but this leads to new sets of problems we haven’t faced before. Our goal is to resolve each and one of those problems. We strive for innovation to provide new solutions for our clients while maintaining flexibility in our delivery." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


