import React from 'react';
import {Link} from "react-router-dom";
import {Accordion, Button, Card} from "react-bootstrap";


const JobTypeFilter = () => {
    return (
        <Accordion className={`rn-accordion-style rn-accordion-04 mt--40`} defaultActiveKey="0">
            <Card>
                <Card.Header >
                    <Accordion.Toggle   as={Button} variant="link" eventKey="0">
                       Job Type
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <div className="filter-single-widget widget_filter">
                        <ul >
                            <li>
                                <Link to={'#'}>
                                <span>Full Time</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'#'}>
                                    <span>Contractor</span></Link>
                            </li>
                        </ul>
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default JobTypeFilter
