import React from 'react';
import {slugify} from "../../utils";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const JobsItem = ({ data, StyleVar }) => {

    return (
        <div className={` ${StyleVar}`}>
            <div className="inner">
                <div className="content">
                    <h4 className="title">
                        <Link to={process.env.PUBLIC_URL + `/jobs/${data.id}`}>
                            {data.title}
                        </Link>
                    </h4>
                    <ul className="rn-meta-list">
                        <li key={"contractType"}>{data.contractType}</li>
                        <li key={"separator"} className="separator">/</li>
                        {data.locations.map((each, i) =>(
                            <>        <li key={i}>{each}</li>
                                <li key={i+"-separator"} className="separator">/</li>
                            </>
                        ))}
                        <li key={"date"}>{data.date}</li>
                    </ul>
                    <p className="descriptiion">{data.responsibilities.length  >= 250 ? data.responsibilities.slice(0, 250)+"..." : data.responsibilities}</p>
                </div>
            </div>
        </div>
    )
}
JobsItem.propTypes = {
    data: PropTypes.object
};
export default JobsItem;
