import {FiActivity, FiCast, FiMap} from "react-icons/fi";

export const RPOModels = [
    {
        icon: <FiActivity />,
        title: 'End-to-End RPO',
        description: 'The entire recruitment cycle, everything from sourcing to onboarding candidates, is managed by us.\n'
    },
    {
        icon: <FiCast />,
        title: 'Modular RPO',
        description: 'Outsourcing specific parts of the recruitment process. Flexibility to keep certain parts of the process in-house, outsourced, or both.'
    },
    {
        icon: <FiMap />,
        title: 'Project RPO',
        description: 'We will manage the recruitment and onboarding for certain projects. Jump start your projects with a flexible team.'
    },
]