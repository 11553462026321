import React from 'react';
import SidebarTag from './../../components/blog/sidebar/SidebarTag';
import SideCategories from './../../components/blog/sidebar/SideCategories';
import Comment from './../../components/blog/Comment';
import {slugify} from "../../utils";
import {Link} from "react-router-dom";
import { FiUser, FiCalendar, FiFolder, FiMapPin} from "react-icons/fi";
import ContactForm from "../../elements/contact/ContactForm";
import GoogleMapStyle from "../../elements/contact/GoogleMapStyle";



const JobDetailsContent = ({ data }) => {
    return (
        <>
            <div className="post-page-banner rn-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content text-center">
                                <div className="page-title">
                                    <h1 className="theme-gradient">{data.title}</h1>
                                    <h5>{data.number}</h5>
                                </div>
                                <ul className="rn-meta-list">
                                    <li><FiUser/>{data.company}</li>
                                    <li><FiFolder />{data.contractType}</li>
                                    <li><FiMapPin/>{data.locations.map((each, i)=>(i !== data.locations.length -1 ? each + " | " : each))}</li>
                                    <li><FiCalendar />{data.date}</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-details-content pt--60 rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content">

                                {/*Responsibilities*/}
                                <h4 className="title mb--40">Responsibilities</h4>
                                <div dangerouslySetInnerHTML={{__html: data.responsibilities}} />

                                {/*Required Skill*/}
                                <h4 className="title mb--40 pt--60">Required Skills</h4>
                                <div dangerouslySetInnerHTML={{__html: data.skills}} />

                                {/*<div className="category-meta">*/}
                                {/*    <span className="text">Tags:</span>*/}
                                {/*    <SidebarTag />*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rwt-contact-area">
                <div className="container">
                    <div className="row">
                        <div className="row mt--40 row--15">
                            <div className="col-lg-7">
                                <ContactForm formStyle="contact-form-1" />
                            </div>
                            <div className="col-lg-5 mt_md--30 mt_sm--30">
                                <GoogleMapStyle />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default JobDetailsContent;
