import React from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

import ServiceOne from '../elements/service/ServiceOne';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlpitOne from "../elements/split/SlpitOne";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TeamFour from "../elements/team/TeamFour";
import BrandTwo from "../elements/brand/BrandTwo";
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo";
import Typed from "react-typed";
import AccordionOne from "../elements/accordion/AccordionOne";
import {RPOServices} from "../data/service/RPOServices";
import {RPOModels} from "../data/service/RPOModels";
import ScrollAnimation from "react-animate-on-scroll";
import rpo_img from "../assets/images/bg/rpo-1.jpg";


const RPOTempest = () => {
    return (
        <>
            <SEO title="RPO || Tempest" />
            <main className="page-wrapper">
                <HeaderTopNews />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                <div className="rn-service-area height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Recruitment Process Outsourcing"
                                        description = ""
                                        titleSize = {"L"}
                                    />
                                    {/*<p className="description">We help our clients succeed by creating brand identities, digital experiences, and print materials.</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <div className="rn-service-area rn-section-gap">
                    <div className="slider-area slider-style-1 bg-transparent height-600">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                        {/*<span className="subtitle">DIGITAL CONSULTING AGENCY</span>*/}
                                        <h1 className="title theme-gradient display-one">Our Approach</h1>
                                        <div className="col-lg-12">
                                            <p className={"description"}>It’s not about matching resumes to job descriptions. It’s understanding your business goals, your culture, your needs, and digging into what makes an ideal fit for your organization. Whether you need a highly skilled expert, a permanent placement, or support for a fast-track initiative or additional structure to achieve your goals, we’re here to make it happen.</p>
                                        </div>
                                </div>
                                <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                                    <div className="frame-image">
                                        <img src={rpo_img} style={{borderRadius: 10}} alt="Banner Images" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <div className="rn-service-area rn-section-gap">
                    <div className="slider-area slider-style-1 bg-transparent height-600">
                        <div className="container">
                        <div className="row row--30">
                            <div className="col-lg-6">
                                <div className="mission-title">
                                    <h1 className="title">Tailored to your needs <br /> {" "}</h1>
                                    <p className={"description"}>We help organizations unlock their true potential. We’ll examine your needs to develop a tailored solution to achieve your workforce development goals and help you sustain high business performance.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 mt_md--30 mt_sm--30">
                                <ul className="list-icon">
                                    <li className={"pb-5"}><span className="icon" style={{color: "var(--color-success)"}}><FiCheck /></span>Sourcing and recruitment</li>
                                    <Separator />
                                    <li  className={"pt-5 pb-5"}><span className="icon" style={{color: "var(--color-success)"}}><FiCheck /></span>Pre-employment Screening</li>
                                    <Separator />
                                    <li className={"pt-5 pb-5"}><span className="icon" style={{color: "var(--color-success)"}}><FiCheck /></span>Permanent and temporary placements</li>
                                </ul>
                            </div>
                            <div className="col-lg-3 mt_md--30 mt_sm--30">
                                <ul className="list-icon">
                                    <li className={"pb-5"}><span className="icon" style={{color: "var(--color-success)"}}><FiCheck /></span>Technical Assessment</li>
                                    <Separator />
                                    <li className={"pt-5 pb-5"}><span className="icon" style={{color: "var(--color-success)"}}><FiCheck /></span>Data and analytics</li>
                                    <Separator />
                                    <li className={"pt-5 pb-5"}><span className="icon" style={{color: "var(--color-success)"}}><FiCheck /></span>Compliance and risk management</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>


                <Separator />
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="slider-area slider-style-1 bg-transparent height-600">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Find the right fit for your business"
                                    description = "Our goal when engaging with clients is to be as flexible as possible and carry out various hiring strategies for various needs.
                                     <br /> We identify the needs of our client and provide a flexible recruitment process that can be adjusted at a moment’s notice."
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                            description
                            services={RPOModels}
                        />
                    </div>
                    </div>
                </div>
                {/* End Service Area  */}
                <Separator />
                {/*<SlpitOne />*/}

                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Specialized Needs"
                                    description = "We know that finding the right talent is not an easy task. At Tempest, we have a team of highly skilled technical recruiters that can source, recruit, and screen candidates based on your needs."
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-start"
                            description={false}
                            services={RPOServices}
                        />
                    </div>
                </div>

                {/* End Brand Area  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default RPOTempest;
