import React from 'react';
import {Link} from "react-router-dom";
import {Accordion, Button, Card} from "react-bootstrap";
import {tagMap} from "../../../data/jobs/tagMap";


const TagFilter = ({addTag, removeTag, tags}) => {
    const tagFilterHandler = (tagId) => {
        if(tags.includes(tagId)){
            removeTag(tagId)
        }else {
            addTag(tagId)
        }
    }

    return (

        <ul className="tagcloud">
            {Object.keys(tagMap).map((each, i)=>
                <Link key={i} to={"#"} onClick={()=>tagFilterHandler(tagMap[each].id)}>
                    {tagMap[each].name}
                </Link>
            )}
        </ul>
    )
}

export default TagFilter
